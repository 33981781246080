<!--客户中心--建筑物详情页-->
<template>
  <div
    id="areaDetails"
    ref="areaDetails"
    v-loading="loading"
    :element-loading-text="$t('customerCenter.a1')"
  >
    <!-- 1 -->
    <!--头部标题-->
    <div class="header">
      <div class="header_title">
        <img src="../../../public/images/set/home_normal.png" />
        <div>
          <span>{{ areaData.name }}</span>
          <br />
          <span class="description">{{ areaData.description | none }}</span>
        </div>
        <a-icon
          type="form"
          @click="openareaalter"
          :style="{ fontSize: '20px', color: '#7682CE' }"
        />
      </div>
      <div>
        <a-icon
          type="close"
          @click="returnTo"
          :style="{ fontSize: '20px', color: '#7682CE' }"
        />
      </div>
    </div>
    <!-- 2 -->
    <!--功能站点卡片-->
    <div :class="styleName">
      <div class="title">{{ $t("customerCenter.a19") }}</div>
      <swiper :options="swiperOption" pagination="true" ref="mySwiper">
        <swiper-slide v-for="(itme, index) in siteList" :key="index">
          <div
            :class="select == itme.site.id ? 'selectcard' : 'card'"
            @click="cardselect(itme, index)"
          >
            <img :src="getimgs(itme.site.siteContractType)" />
            <br />
            <div :title="itme.site.name">
              <span>{{ itme.site.name }}</span>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <!-- 3 -->
    <!--主内容区域-->
    <div class="main" ref="main" @scroll="paperScroll()">
      <div v-if="select !== undefined">
        <!-- 3.1 -->
        <!--站点信息-->
        <div class="chunk">
          <div class="title">{{ $t("customerCenter.a22") }}</div>
          <div class="siteinfocontent">
            <div class="siteinfo">
              <div class="line">
                <p>{{ $t("customerCenter.a23") }}</p>
                <span>{{ siteinfo.site.name }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a10") }}</p>
                <span>{{ siteinfo.site.kana | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a25") }}</p>
                <span>{{ siteinfo.site.manageNo | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a15") }}</p>
                <span>{{ siteinfo.site.contactClient | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a12") }}</p>
                <span>{{ siteinfo.site.daytimeContact | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a14") }}</p>
                <span>{{ siteinfo.site.nighttimeContact | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a17") }}</p>
                <span>{{ siteinfo.site.address | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a27") }}</p>
                <span>{{ siteinfo.sgw.sgwCode | none }}</span>
              </div>
              <div class="line">
                <p>{{ $t("customerCenter.a28") }}</p>
                <span>{{ siteinfo.sgw.sgwName | none }}</span>
              </div>
            </div>
            <div>
              <a-icon
                type="form"
                @click="opensiteset"
                :style="{ fontSize: '20px', color: '#7682CE' }"
              />
            </div>
          </div>
        </div>
        <!-- 3.2 -->
        <!--日历设置-->
        <div class="chunk" ref="calendarcalendar">
          <div class="title">{{ $t("customerCenter.a20") }}</div>
          <div class="calendarmodel">
            <div class="calendar-container">
              <!-- 日历选择菜单 -->
              <div class="calendar-select" ref="calendarselect">
                <a-select
                  v-model="calendarlistvalue"
                  @select="calendarlistselect"
                  :getPopupContainer="() => this.$refs.calendarselect"
                  size="small"
                  style="width: 130px"
                >
                  <a-select-option
                    v-for="(item, index) in calendarlist"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-icon
                  type="form"
                  @click="openNameUpdate"
                  :style="{
                    fontSize: '20px',
                    color: '#7682CE',
                    'margin-left': '10px',
                  }"
                />
              </div>
              <!-- 日历面版 -->
              <a-calendar :fullscreen="false" v-model="calendarvalue">
                <ul slot="dateCellRender" slot-scope="value" class="events">
                  <li
                    v-for="item in getListData(value)"
                    :key="item.content"
                    class="dot"
                  >
                    <a-badge :color="item.mode == 1 ? '#7682ce' : '#f8b62e'" />
                  </li>
                </ul>
              </a-calendar>
            </div>
            <!-- 节日列表 -->
            <ul class="calendar-ul">
              <a-button
                class="button"
                type="primary"
                ghost
                @click="openfestival()"
              >
                <a-icon type="plus" />{{ $t("customerCenter.a21") }}
              </a-button>
              <li
                v-for="item in festivalData"
                :key="item.id"
                class="calendar-li"
              >
                <div
                  :class="
                    item.mode == 1 ? 'calendar-li-purple' : 'calendar-li-orange'
                  "
                ></div>
                <div class="calendar-li-left">
                  <span class="li-title">{{ item.date }}</span>
                  <br />
                  <span class="li-row">{{ item.name }}</span>
                </div>
                <div class="calendar-li-right">
                  <a-icon
                    type="form"
                    @click="festivalCompile(item)"
                    :style="{
                      fontSize: '20px',
                      color: '#7682CE',
                      'margin-right': '10px',
                    }"
                  />
                  <a-icon
                    type="delete"
                    @click="deleteFestival(item)"
                    :style="{ fontSize: '20px', color: '#7682CE' }"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 3.3 -->
        <!--楼层信息-->
        <div class="chunk">
          <div class="title">{{ $t("customerCenter.a29") }}</div>
          <div class="tablemodel">
            <a-table
              :columns="tenantcolumns"
              :dataSource="tenantlist"
              :pagination="false"
              :rowKey="(record) => record.id"
            >
              <template slot="chaozuo" slot-scope="text, record">
                <a-button type="primary" @click="floorCompile(record)">
                  {{ $t("customerCenter.a30") }}
                </a-button>
              </template>
            </a-table>
          </div>
        </div>
        <!-- 3.4 -->
        <!--合同信息-->
        <div class="chunk">
          <div class="title">{{ $t("customerCenter.a31") }}</div>
          <div class="tablemodel">
            <a-table
              :columns="columns"
              :dataSource="contractlist"
              :pagination="false"
              :rowKey="(record) => record.id"
            >
              <template slot="chaozuo" slot-scope="text, record">
                <a-icon
                  type="form"
                  @click="contractCompile(record)"
                  :style="{ fontSize: '20px', color: '#7682CE' }"
                />
              </template>
              <template slot="contractStatus" slot-scope="text, record">
                <div class="contractStatus">
                  <div
                    :class="record.contractStatus == 1 ? 'selectbut' : 'but'"
                  >
                    {{ $t("customerCenter.a32") }}
                  </div>
                  <div
                    :class="record.contractStatus == 2 ? 'selectbut' : 'but'"
                  >
                    {{ $t("customerCenter.a33") }}
                  </div>
                  <div
                    :class="record.contractStatus == 3 ? 'selectbut' : 'but'"
                  >
                    {{ $t("customerCenter.a34") }}
                  </div>
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <!--修改建筑物信息弹窗-->
    <a-modal
      :title="$t('customerCenter.a35')"
      centered
      :visible="areavisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="areaCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="areaCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="areaupdate">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="arealoading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-form-model
          ref="ruleForm"
          :model="areaform"
          layout="vertical"
          :rules="rules"
        >
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a36')" prop="name">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a37')"
                  v-model="areaform.name"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a38')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a39')"
                  v-model="areaform.description"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 5 -->
    <!--站点信息弹窗-->
    <a-modal
      :title="$t('customerCenter.a40')"
      centered
      :width="600"
      :visible="sitevisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="siteCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="siteCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="siteupdate">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="siteloading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-form-model
          ref="siteForm"
          :model="siteform"
          layout="vertical"
          :rules="siterules"
        >
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a23')" prop="name">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a41')"
                  v-model="siteform.name"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a42')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a11')"
                  v-model="siteform.kana"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a12')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a13')"
                  v-model="siteform.daytimeContact"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a14')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a13')"
                  v-model="siteform.nighttimeContact"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a15')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a16')"
                  v-model="siteform.contactClient"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a17')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a18')"
                  v-model="siteform.address"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 6 -->
    <!-- 日历节假日更新活修改弹窗 -->
    <a-modal
      :title="$t('customerCenter.a43')"
      centered
      :width="800"
      :visible="festivalvisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="festivalCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="festivalCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="festivalupdate()">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="festivalloading"
        :element-loading-text="$t('customerCenter.a1')"
        class="festivalSet"
      >
        <a-form-model
          ref="festivalForm"
          :model="festivalCompileData"
          :rules="festivalules"
        >
          <!-- 节日名称输入框 -->
          <a-form-model-item
            :label="$t('customerCenter.a44')"
            prop="holidayName"
          >
            <a-input
              allow-clear
              :placeholder="$t('customerCenter.a45')"
              v-model="festivalCompileData.holidayName"
              style="width: 300px"
            />
          </a-form-model-item>
          <!-- 日期选择 -->
          <a-form-model-item :label="$t('customerCenter.a46')">
            <!-- 日期指定 -->
            <a-radio-group v-model="festivalCompileData.holidayType">
              <a-radio :style="radioStyle" :value="1">
                <span class="span">{{ $t("customerCenter.a47") }}</span>
                <!-- 日期指定--月 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[0].month"
                  :disabled="dateAssign"
                >
                  <a-select-option v-for="item in monthList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 日期指定--日 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[0].day"
                  :disabled="dateAssign"
                >
                  <a-select-option v-for="item in dayList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-radio>
              <!-- 星期指定 -->
              <a-radio :style="radioStyle" :value="2">
                <span class="span">{{ $t("customerCenter.a48") }}</span>
                <!-- 星期选择--月 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[1].month"
                  :disabled="weekAssign"
                >
                  <a-select-option v-for="item in monthList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 星期选择--周 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[1].week"
                  :disabled="weekAssign"
                >
                  <a-select-option v-for="item in weekList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 星期选择--星期 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[1].weekday"
                  :disabled="weekAssign"
                >
                  <a-select-option v-for="item in weeksList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-radio>
              <!-- 重复 -->
              <a-radio :style="radioStyle" :value="repetition">
                <span class="span">{{ $t("customerCenter.a49") }}</span>
                <!-- 重复--重复周/月选择 -->
                <a-select
                  class="a-select"
                  v-model="festivalCompileData.holidayType"
                  :disabled="repetitionType"
                >
                  <a-select-option
                    v-for="item in repetitionList"
                    :key="item.vuale"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 重复--周-->
                <a-select
                  class="a-select"
                  v-model="dateSelect[3].week"
                  :disabled="repetitionWeek"
                >
                  <a-select-option v-for="item in weekList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 重复--星期 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[3].weekday"
                  :disabled="repetitionType"
                >
                  <a-select-option v-for="item in weeksList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-radio>
              <!-- 期限指定 -->
              <a-radio :style="radioStyle" :value="5">
                <span class="span">{{ $t("customerCenter.a50") }}</span>
                <!-- 期限指定--开始月 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[4].month"
                  :disabled="deadlineAssign"
                >
                  <a-select-option v-for="item in monthList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 期限指定--开始日 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[4].day"
                  :disabled="deadlineAssign"
                >
                  <a-select-option v-for="item in dayList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <span class="connect">~</span>
                <!-- 期限指定--结束月 -->
                <a-select
                  style="width: 120px"
                  v-model="dateSelect[4].expMonth"
                  :disabled="deadlineAssign"
                >
                  <a-select-option v-for="item in monthList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- 期限指定--结束日 -->
                <a-select
                  class="a-select"
                  v-model="dateSelect[4].expday"
                  :disabled="deadlineAssign"
                >
                  <a-select-option v-for="item in dayList" :key="item.vuale">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-radio>
            </a-radio-group>
            <!-- 节日类型选择 -->
          </a-form-model-item>
          <a-form-model-item :label="$t('customerCenter.a51')">
            <!-- 节日类型选择--单选框 -->
            <a-radio-group name="radioGroup" v-model="festivalCompileData.mode">
              <a-radio :value="1"> {{ $t("customerCenter.a52") }} </a-radio>
              <a-radio :value="2"> {{ $t("customerCenter.a53") }} </a-radio>
              <a-radio :value="3"> {{ $t("customerCenter.a54") }} </a-radio>
              <a-radio :value="4"> {{ $t("customerCenter.a55") }} </a-radio>
              <a-radio :value="5"> {{ $t("customerCenter.a56") }} </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 7日历列表名称更新 -->
    <a-modal
      :title="$t('customerCenter.a57')"
      centered
      :width="300"
      :visible="namevisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="calendarNameCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="calendarNameCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="updateCalendarName">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="siteloading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-form-model
          ref="CalendarNameForm"
          :model="CalendarNameform"
          layout="vertical"
          :rules="CalendarNamerules"
        >
          <a-form-model-item
            :label="$t('customerCenter.a58')"
            prop="calendarName"
          >
            <a-input
              allow-clear
              :placeholder="$t('customerCenter.a59')"
              v-model="CalendarNameform.calendarName"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 8楼层信息更新 -->
    <a-modal
      :title="$t('customerCenter.a60')"
      centered
      :width="500"
      :visible="floorvisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="floorCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="floorCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="floorInfoUpdate">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="floorloading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-form-model
          ref="tenantData"
          :model="tenantData"
          layout="vertical"
          :rules="tenantDatarules"
        >
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item :label="$t('customerCenter.a61')" prop="name">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a61')"
                  v-model="tenantData.name"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12"
              ><a-form-model-item
                :label="$t('customerCenter.a62')"
                prop="calendarName"
              >
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a62')"
                  v-model="tenantData.nameKana"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customerCenter.a63')"
                prop="calendarName"
              >
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a63')"
                  v-model="tenantData.address"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customerCenter.a64')"
                prop="calendarName"
              >
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a64')"
                  v-model="tenantData.contact1"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customerCenter.a65')"
                prop="calendarName"
              >
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a65')"
                  v-model="tenantData.contact2"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12"> </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 8楼层信息更新 -->
    <a-modal
      :title="$t('customerCenter.a66')"
      centered
      :width="750"
      :visible="contractvisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.areaDetails"
      @cancel="contractCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="contractCancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="contractUpdate">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="contractloading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-table
          :columns="contractcolumns"
          :dataSource="newContractInfo"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <template slot="contractStatus" slot-scope="text, record">
            <div class="contractStatus">
              <div
                :class="record.contractStatus == 1 ? 'selectbut' : 'but'"
                @click="setContractStatus(1, record)"
              >
                {{ $t("customerCenter.a67") }}
              </div>
              <div
                :class="record.contractStatus == 2 ? 'selectbut' : 'but'"
                @click="setContractStatus(2, record)"
              >
                {{ $t("customerCenter.a68") }}
              </div>
              <div
                :class="record.contractStatus == 3 ? 'selectbut' : 'but'"
                @click="setContractStatus(3, record)"
              >
                {{ $t("customerCenter.a69") }}
              </div>
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import {
  getsitelistandctrl,
  getsitecontractlist,
  getcontractlist,
  gettenantlist,
  getcalendarlist,
  buildingupdate,
  siteupdate,
  monthholiday,
  updateName,
  updateFestival,
  deleteFestival,
  tenantupdate,
  addContract,
} from "../../api/setManagement";
import moment from "moment";

export default {
  name: "areaDetails",
  data() {
    return {
      areaData: undefined,
      areaform: {},
      areavisible: false,
      arealoading: false,
      siteList: [],
      sitevisible: false,
      siteloading: false,
      loading: false,
      select: undefined,
      siteinfo: undefined,
      index: undefined,
      siteform: {},
      floorloading: false,
      calendarlistvalue: undefined,
      calendarName: undefined,
      festivalvisible: false,
      contractvisible: false,
      festivalform: {},
      floorvisible: false,
      contractloading: false,
      calendarvalue: moment(),
      nowMonth: moment().format("M"),
      styleName: "not_box_sitechunk",
      namevisible: false,
      festivalloading: false,
      CalendarNameform: {
        calendarName: undefined,
      },
      radioStyle: {
        display: "block",
        height: "45px",
        lineHeight: "45px",
      },
      monthList: [
        { name: this.$t("customerCenter.a70"), vuale: 1 },
        { name: this.$t("customerCenter.a71"), vuale: 2 },
        { name: this.$t("customerCenter.a72"), vuale: 3 },
        { name: this.$t("customerCenter.a73"), vuale: 4 },
        { name: this.$t("customerCenter.a74"), vuale: 5 },
        { name: this.$t("customerCenter.a75"), vuale: 6 },
        { name: this.$t("customerCenter.a76"), vuale: 7 },
        { name: this.$t("customerCenter.a77"), vuale: 8 },
        { name: this.$t("customerCenter.a78"), vuale: 9 },
        { name: this.$t("customerCenter.a79"), vuale: 10 },
        { name: this.$t("customerCenter.a80"), vuale: 11 },
        { name: this.$t("customerCenter.a81"), vuale: 12 },
      ],
      dayList: [
        { name: this.$t("customerCenter.a82"), vuale: 1 },
        { name: this.$t("customerCenter.a83"), vuale: 2 },
        { name: this.$t("customerCenter.a84"), vuale: 3 },
        { name: this.$t("customerCenter.a85"), vuale: 4 },
        { name: this.$t("customerCenter.a86"), vuale: 5 },
        { name: this.$t("customerCenter.a87"), vuale: 6 },
        { name: this.$t("customerCenter.a88"), vuale: 7 },
        { name: this.$t("customerCenter.a89"), vuale: 8 },
        { name: this.$t("customerCenter.a90"), vuale: 9 },
        { name: this.$t("customerCenter.a91"), vuale: 10 },
        { name: this.$t("customerCenter.a92"), vuale: 11 },
        { name: this.$t("customerCenter.a93"), vuale: 12 },
        { name: this.$t("customerCenter.a94"), vuale: 13 },
        { name: this.$t("customerCenter.a95"), vuale: 14 },
        { name: this.$t("customerCenter.a96"), vuale: 15 },
        { name: this.$t("customerCenter.a97"), vuale: 16 },
        { name: this.$t("customerCenter.a98"), vuale: 17 },
        { name: this.$t("customerCenter.a99"), vuale: 18 },
        { name: this.$t("customerCenter.a100"), vuale: 19 },
        { name: this.$t("customerCenter.a101"), vuale: 20 },
        { name: this.$t("customerCenter.a102"), vuale: 21 },
        { name: this.$t("customerCenter.a103"), vuale: 22 },
        { name: this.$t("customerCenter.a104"), vuale: 23 },
        { name: this.$t("customerCenter.a105"), vuale: 24 },
        { name: this.$t("customerCenter.a106"), vuale: 25 },
        { name: this.$t("customerCenter.a107"), vuale: 26 },
        { name: this.$t("customerCenter.a108"), vuale: 27 },
        { name: this.$t("customerCenter.a109"), vuale: 28 },
        { name: this.$t("customerCenter.a110"), vuale: 29 },
        { name: this.$t("customerCenter.a111"), vuale: 30 },
        { name: this.$t("customerCenter.a112"), vuale: 31 },
      ],
      weekList: [
        { name: this.$t("customerCenter.a113"), vuale: 1 },
        { name: this.$t("customerCenter.a114"), vuale: 2 },
        { name: this.$t("customerCenter.a115"), vuale: 3 },
        { name: this.$t("customerCenter.a116"), vuale: 4 },
        { name: this.$t("customerCenter.a117"), vuale: 5 },
      ],
      weeksList: [
        { name: this.$t("customerCenter.a118"), vuale: 1 },
        { name: this.$t("customerCenter.a119"), vuale: 2 },
        { name: this.$t("customerCenter.a120"), vuale: 3 },
        { name: this.$t("customerCenter.a121"), vuale: 4 },
        { name: this.$t("customerCenter.a122"), vuale: 5 },
        { name: this.$t("customerCenter.a123"), vuale: 6 },
        { name: this.$t("customerCenter.a124"), vuale: 7 },
      ],
      repetitionList: [
        { name: this.$t("customerCenter.a125"), vuale: 3 },
        { name: this.$t("customerCenter.a126"), vuale: 4 },
      ],
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
      contractlist: [],
      newContractInfo: [],
      oldContractInfo: {},
      tenantlist: [],
      tenantData: {},
      tenantcolumns: [
        {
          title: this.$t("customerCenter.a61"),
          dataIndex: "name",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a62"),
          customRender: (text, record, index) => {
            if (record.nameKana == null) {
              return "--";
            } else {
              return record.nameKana;
            }
          },
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a63"),
          customRender: (text, record, index) => {
            if (record.address == null) {
              return "--";
            } else {
              return record.address;
            }
          },
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a64"),
          customRender: (text, record, index) => {
            if (record.contact1 == null) {
              return "--";
            } else {
              return record.contact1;
            }
          },
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a65"),
          customRender: (text, record, index) => {
            if (record.contact2 == null) {
              return "--";
            } else {
              return record.contact2;
            }
          },
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a127"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          align: "center",
        },
      ],
      columns: [
        {
          title: this.$t("customerCenter.a128"),
          dataIndex: "name",
          width: 250,
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a129"),
          dataIndex: "contractStatus",
          scopedSlots: { customRender: "contractStatus" },
          ellipsis: true,
        },
        {
          title: this.$t("customerCenter.a127"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 90,
          align: "center",
        },
      ],
      contractcolumns: [
        {
          title: this.$t("customerCenter.a128"),
          dataIndex: "name",
          width: 200,
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("customerCenter.a129"),
          dataIndex: "contractStatus",
          scopedSlots: { customRender: "contractStatus" },
          ellipsis: true,
        },
      ],
      calendarlist: [],
      festivallist: [],
      festivalData: [],
      dateSelect: [
        { month: 1, day: 1 },
        { month: 1, week: 1, weekday: 1 },
        { weekday: 1 },
        { week: 1, weekday: 1 },
        { expMonth: 1, expday: 1, month: 1, day: 1 },
      ],
      festivalCompileData: {
        holidayName: "",
        holidayId: 0,
        holidayType: 1,
        mode: 1,
        month: 0,
        day: 0,
        expMonth: 0,
        expDay: 0,
        week: 0,
        weekDay: 0,
        operate: "update",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("customerCenter.a37"),
            trigger: "blur",
          },
        ],
      },
      siterules: {
        name: [
          {
            required: true,
            message: this.$t("customerCenter.a41"),
            trigger: "blur",
          },
        ],
      },
      tenantDatarules: {
        name: [
          {
            required: true,
            message: this.$t("customerCenter.a41"),
            trigger: "blur",
          },
        ],
      },
      festivalules: {
        holidayName: [
          {
            required: true,
            message: this.$t("customerCenter.a45"),
            trigger: "blur",
          },
        ],
      },
      CalendarNamerules: {
        calendarName: [
          {
            required: true,
            message: this.$t("customerCenter.a59"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 过滤器
  filters: {
    none(msg) {
      if (!msg) {
        return "----";
      } else {
        return msg;
      }
    },
  },

  watch: {
    // 获取建筑物信息
    "$store.getters.area": {
      handler(newval) {
        this.areaData = newval;
      },
      deep: true,
      immediate: true,
    },
    //获取功能站点列表
    areaData: {
      handler(newval) {
        // console.log("areaData: ", newval);
        this.loading = true;
        let id = newval.id;
        getsitelistandctrl(id)
          .then((res) => {
            // console.log(res);
            this.loading = false;
            let { data } = res;
            this.siteList = data;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      },
      deep: true,
      immediate: true,
    },
    // 监听日历选择日期。
    // 1.获取当前月份判断是否需要请求节日数据。
    // 2.选择日是否有节日，有返回数据给listData
    calendarvalue: {
      handler(newval) {
        // 声明当前月份和选择月份
        const nowMonth = this.nowMonth;
        const selectMonth = newval.format("M");
        // 判断是否选择其他月份
        // console.log(nowMonth, selectMonth);
        if (nowMonth !== selectMonth) {
          // 选择其他月份则请求选择月份的节日数据
          const date = {
            caleId: this.calendarlistvalue,
            month: selectMonth,
          };
          monthholiday(date)
            .then((res) => {
              console.log(res);
              const { data } = res;
              this.festivallist = data;
              this.nowMonth = selectMonth;
              this.getNowDateHoliday(newval);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.getNowDateHoliday(newval);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    repetition() {
      const value = this.festivalCompileData.holidayType;
      if (value == 3) {
        return 3;
      } else {
        return 4;
      }
    },
    repetitionType() {
      const value = this.festivalCompileData.holidayType;
      if (value == 3 || value == 4) {
        return false;
      } else {
        return true;
      }
    },
    repetitionWeek() {
      const value = this.festivalCompileData.holidayType;
      if (value == 4) {
        return false;
      } else {
        return true;
      }
    },
    deadlineAssign() {
      const value = this.festivalCompileData.holidayType;
      if (value == 5) {
        return false;
      } else {
        return true;
      }
    },
    dateAssign() {
      const value = this.festivalCompileData.holidayType;
      if (value == 1) {
        return false;
      } else {
        return true;
      }
    },
    weekAssign() {
      const value = this.festivalCompileData.holidayType;
      if (value == 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    moment,
    // 监听类名main元素的滚动事件
    paperScroll() {
      const top = this.$refs.main.scrollTop;
      if (top == 0) {
        this.styleName = "not_box_sitechunk";
      } else {
        this.styleName = "box_sitechunk";
      }
    },
    // 功能站点卡片图标获取
    getimgs(value) {
      switch (value) {
        case 1: //门禁
          return require("../../../public/images/moduleimg/door.png");
          break;
        case 2: //通用设备管理
          return require("../../../public/images/moduleimg/equipment.png");
          break;
        case 3: //多联机空调
          return require("../../../public/images/moduleimg/vrfac.png");
          break;
        case 4: //水冷空调
          return require("../../../public/images/moduleimg/bsdsac.png");
          break;
        case 5: //其他空调
          return require("../../../public/images/moduleimg/airconditioner.png");
          break;
        case 6: //环境
          return require("../../../public/images/moduleimg/environment.png");
          break;
        case 7: //照明
          return require("../../../public/images/moduleimg/light.png");
          break;
        case 8: //电能
          return require("../../../public/images/moduleimg/electricity.png");
          break;
        case 9: //水能
          return require("../../../public/images/moduleimg/water.png");
          break;
        case 10: //视频监控
          return require("../../../public/images/moduleimg/video.png");
          break;
        case 11: //电梯管理
          return require("../../../public/images/moduleimg/elevator.png");
          break;
        case 12: //消费管理
          return require("../../../public/images/moduleimg/consumption.png");
          break;
        case 13: //出入口
          return require("../../../public/images/moduleimg/passageway.png");
          break;
        case 14: //访客
          return require("../../../public/images/moduleimg/visitor.png");
          break;
        case 15: //考勤
          return require("../../../public/images/moduleimg/checkingIn.png");
          break;
        case 16: //会议
          return require("../../../public/images/moduleimg/conference.png");
          break;
        default:
          return require("../../../public/images/moduleimg/else.png");
      }
    },
    // 页面关闭按钮
    returnTo() {
      this.$router.go(-1);
    },
    // 功能站点卡片选择
    async cardselect(itme, index) {
      this.index = index;
      this.calendarvalue = moment();
      const selectVal = this.select;
      const siteVal = itme.site.id;
      if (selectVal !== siteVal) {
        this.loading = true;
        this.select = siteVal;
        this.siteinfo = itme;
        const data = {
          siteId: siteVal,
        };
        let contractlist = [];
        let sitecontractlist = [];
        // 获取全部合同
        await getcontractlist()
          .then((res) => {
            console.log(res);
            const { data } = res;
            contractlist = data;
          })
          .catch((err) => {
            console.log(err);
          });
        // 获取站点合同
        await getsitecontractlist(data)
          .then((res) => {
            console.log(res);
            const { data } = res;
            sitecontractlist = data;
          })
          .catch((err) => {
            console.log(err);
          });
        for (let i = 0; i < contractlist.length; i++) {
          contractlist[i].contractStatus = 1;
          let result = sitecontractlist.findIndex(
            (row) => row.contractType === contractlist[i].id
          );
          if (result !== -1) {
            contractlist[i].contractStatus =
              sitecontractlist[result].contractStatus;
            contractlist[i].contractId = sitecontractlist[result].id;
          }
        }

        this.contractlist = contractlist;
        // 获取楼层信息
        await gettenantlist(siteVal)
          .then((res) => {
            // console.log(res);
            const { data } = res;
            this.tenantlist = data;
          })
          .catch((err) => {
            console.log(err);
          });
        // 获取站点日历列表
        await getcalendarlist(data)
          .then((res) => {
            // console.log("qq", res);
            const { data } = res;
            this.calendarlist = data;
            this.calendarlistvalue = data[0].value;
          })
          .catch((err) => {
            console.log(err);
          });
        // 根据日历列表id获取日历节日
        const caleId = this.calendarlistvalue;
        const month = moment().format("MM");
        const date = {
          caleId,
          month,
        };
        await monthholiday(date)
          .then((res) => {
            // console.log(res);
            const { data } = res;
            this.festivallist = data;
            this.getNowDateHoliday(this.calendarvalue);
          })
          .catch((err) => {
            console.log(err);
          });
        this.loading = false;
      } else {
        return false;
      }
    },
    // 打开修改建筑物信息
    openareaalter() {
      this.areavisible = true;
      const _val = JSON.stringify(this.areaData);
      const val = JSON.parse(_val);
      this.areaform = val;
    },
    // 修改建筑物信息取消按钮
    areaCancel() {
      this.areavisible = false;
    },
    //建筑物信息设置弹窗确定按钮
    areaupdate() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.arealoading = true;
          const id = this.areaform.id;
          const data = {
            name: this.areaform.name,
            parentId: this.areaform.parentId,
            description:
              this.areaform.description == null
                ? ""
                : this.areaform.description,
          };
          // console.log(id, data);
          buildingupdate(id, data)
            .then((res) => {
              // console.log(res);
              this.arealoading = false;
              if (res.errorCode == "00") {
                const { data } = res;
                this.$store.commit("areaDetails/areaDetails", data);
                this.$message.success(res.msg);
                this.areavisible = false;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.arealoading = false;
            });
        } else {
          return false;
        }
      });
    },
    //日历下拉菜单选择获取对应的日历节日
    calendarlistselect(value) {
      const caleId = value;
      const month = this.calendarvalue.format("M");
      const data = {
        caleId,
        month,
      };
      monthholiday(data)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.festivallist = data;
          this.getNowDateHoliday(this.calendarvalue);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 日历节日渲染
    getListData(value) {
      const list = this.festivallist;
      const month = value.month() + 1;
      const day = value.date();
      const week = Math.ceil(value.date() / 7);
      const weekday = value.day() == 0 ? 7 : value.day();
      let listData = [];
      for (let i = 0; i < list.length; i++) {
        const type = list[i].type;
        const _day = list[i].day;
        const _month = list[i].month;
        const _week = list[i].week;
        const _weekday = list[i].weekday;
        switch (type) {
          case 1:
            if (_day == day && _month == month) {
              listData.push(list[i]);
            }
            break;
          case 2:
            if (_month == month && _week == week && _weekday == weekday) {
              listData.push(list[i]);
            }
            break;
          case 3:
            if (_weekday == weekday) {
              listData.push(list[i]);
            }
            break;
          case 4:
            if (_week == week && _weekday == weekday) {
              listData.push(list[i]);
            }
            break;
          case 5:
            if (_day == day && _month == month) {
              listData.push(list[i]);
            }
            break;
          default:
            return false;
        }
      }
      return listData;
    },
    // 打开站点信息设置弹窗
    opensiteset() {
      this.sitevisible = true;
      const _val = JSON.stringify(this.siteinfo);
      const val = JSON.parse(_val);
      this.siteform = val.site;
    },
    // 站点信息设置的取消按钮
    siteCancel() {
      this.sitevisible = false;
    },
    // 站点弹窗确定按钮
    siteupdate() {
      this.$refs.siteForm.validate((valid) => {
        if (valid) {
          this.siteloading = true;
          const data = {
            siteId: this.siteform.id == null ? "" : this.siteform.id,
            siteName: this.siteform.name == null ? "" : this.siteform.name,
            siteOtherName: this.siteform.kana == null ? "" : this.siteform.kana,
            addr: this.siteform.address == null ? "" : this.siteform.address,
            contacter:
              this.siteform.contactClient == null
                ? ""
                : this.siteform.contactClient,
            tel1:
              this.siteform.daytimeContact == null
                ? ""
                : this.siteform.daytimeContact,
            tel2:
              this.siteform.nighttimeContact == null
                ? ""
                : this.siteform.nighttimeContact,
            remark: "",
          };
          console.log(data);
          siteupdate(data)
            .then((res) => {
              console.log(res);
              this.siteloading = false;
              if (res.errorCode == "00") {
                const { data } = res;
                this.$message.success(res.msg);
                this.siteinfo.site = data;
                this.siteList[this.index].site = data;
                this.sitevisible = false;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.siteloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 打开节日设置弹窗
    openfestival() {
      const data = {
        holidayName: "",
        holidayId: 0,
        holidayType: 1,
        mode: 1,
        month: 0,
        day: 0,
        expMonth: 0,
        expDay: 0,
        week: 0,
        weekDay: 0,
        operate: "new",
      };
      this.festivalCompileData = data;
      this.festivalvisible = true;
    },
    // 节日设置弹窗取消按钮
    festivalCancel() {
      this.festivalvisible = false;
    },
    // 节日设置弹窗确定按钮
    festivalupdate() {
      this.$refs.festivalForm.validate((valid) => {
        if (valid) {
          this.festivalloading = true;
          const selectValue = this.dateSelect;
          const _data = JSON.stringify(this.festivalCompileData);
          const data = JSON.parse(_data);
          data.caleId = this.calendarlistvalue;
          switch (data.holidayType) {
            case 1:
              data.month = selectValue[0].month;
              data.day = selectValue[0].day;
              break;
            case 2:
              data.month = selectValue[1].month;
              data.week = selectValue[1].week;
              data.weekday = selectValue[1].weekday;
              break;
            case 3:
              data.week = selectValue[3].weekday;
              break;
            case 4:
              data.week = selectValue[3].week;
              data.weekday = selectValue[3].weekday;
              break;
            case 5:
              data.month = selectValue[4].month;
              data.day = selectValue[4].day;
              data.expMonth = selectValue[4].expMonth;
              data.expDay = selectValue[4].expDay;
              break;
            default:
              return false;
          }
          updateFestival(data)
            .then((res) => {
              this.festivalloading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.festivalvisible = false;
                this.getNowMonthFestival();
              } else {
                this.$message.error(res.msg);
              }
              // console.log(res);
            })
            .catch((err) => {
              this.festivalloading = false;
              console.log(err);
            });
          // console.log(this.festivalCompileData);
        } else {
          return false;
        }
      });
    },
    // 获取当前月份节日
    getNowMonthFestival() {
      const caleId = this.calendarlistvalue;
      const month = moment().format("MM");
      const date = {
        caleId,
        month,
      };
      monthholiday(date)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.festivallist = data;
          this.getNowDateHoliday(this.calendarvalue);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前日期节日数据
    getNowDateHoliday(value) {
      const day = value.date();
      const month = value.month() + 1;
      const week = Math.ceil(value.date() / 7);
      const weekday = value.day() == 0 ? 7 : value.day();
      const _list = JSON.stringify(this.festivallist);
      const list = JSON.parse(_list);
      const date = value.format("YYYY/MM/DD");
      let listData = [];
      for (let i = 0; i < list.length; i++) {
        const type = list[i].type;
        const _day = list[i].day;
        const _month = list[i].month;
        const _week = list[i].week;
        const _weekday = list[i].weekday;
        switch (type) {
          case 1:
            if (_day == day && _month == month) {
              list[i].date = date;
              listData.push(list[i]);
            }
            break;
          case 2:
            if (_month == month && _week == week && _weekday == weekday) {
              list[i].date = date;
              listData.push(list[i]);
            }
            break;
          case 3:
            if (_weekday == weekday) {
              list[i].date = date;
              listData.push(list[i]);
            }
            break;
          case 4:
            if (_week == week && _weekday == weekday) {
              list[i].date = date;
              listData.push(list[i]);
            }
            break;
          case 5:
            if (_day == day && _month == month) {
              list[i].date = date;
              listData.push(list[i]);
            }
            break;
          default:
            return false;
        }
      }
      this.festivalData = listData;
    },
    // 打开日历列表名称更新弹窗
    openNameUpdate() {
      this.namevisible = true;
      const calendarlist = this.calendarlist;
      const calendarlistvalue = this.calendarlistvalue;
      let result = calendarlist.findIndex(
        (index) => index.value === calendarlistvalue
      );
      const name = calendarlist[result].name;
      this.CalendarNameform.calendarName = name;
    },
    // 关闭日历列表名称更新弹窗
    calendarNameCancel() {
      this.namevisible = false;
    },
    // 更新日历列表名称
    updateCalendarName() {
      this.$refs.CalendarNameForm.validate((valid) => {
        if (valid) {
          const siteId = this.select;
          const calId = this.calendarlistvalue;
          const calName = this.CalendarNameform.calendarName;
          const data = {
            siteId,
            calId,
            calName,
          };
          updateName(data)
            .then((res) => {
              // console.log(res);
              if (res.errorCode == "00") {
                this.namevisible = false;
                this.$message.success(res.msg);
                const data = {
                  siteId: this.select,
                };
                getcalendarlist(data)
                  .then((res) => {
                    const { data } = res;
                    this.calendarlist = data;
                    this.calendarlistvalue = data[0].value;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开节日编辑弹窗
    festivalCompile(value) {
      console.log(value);
      this.festivalvisible = true;
      this.festivalCompileData.holidayType = value.type;
      this.festivalCompileData.holidayName = value.name;
      this.festivalCompileData.holidayId = value.id;
      this.festivalCompileData.operate = "update";
      this.festivalCompileData.mode = value.mode;
      const selectValue = this.dateSelect;
      switch (value.type) {
        case 1:
          selectValue[0].month = value.month;
          selectValue[0].day = value.day;
          break;
        case 2:
          selectValue[1].month = value.month;
          selectValue[1].week = value.week;
          selectValue[1].weekday = value.weekday;
          break;
        case 3:
          selectValue[3].weekday = value.weekday;
          break;
        case 4:
          selectValue[1].week = value.week;
          selectValue[1].weekday = value.weekday;
          break;
        case 5:
          selectValue[4].month = value.month;
          selectValue[4].day = value.day;
          selectValue[4].expMonth = value.expMonth;
          selectValue[4].expDay = value.expDay;
          break;
        default:
          return false;
      }
    },
    // 节日删除
    deleteFestival(value) {
      this.$confirm({
        title: this.$t("customerCenter.a131"),
        content: this.$t("customerCenter.a132"),
        centered: true,
        onOk: () => {
          const caleId = this.calendarlistvalue;
          const holidayId = value.id;
          const data = {
            caleId,
            holidayId,
          };
          deleteFestival(data)
            .then((res) => {
              // console.log(res);
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.getNowMonthFestival();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });

      console.log(value);
    },
    // 打开楼层信息更新弹窗
    floorCompile(value) {
      // console.log(value);
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      this.tenantData = val;
      this.floorvisible = true;
    },
    // 关闭楼层信息更新弹窗
    floorCancel() {
      this.floorvisible = false;
    },
    // 楼层信息更新接口
    floorInfoUpdate() {
      this.$refs.tenantData.validate((valid) => {
        if (valid) {
          this.floorloading = true;
          const tenantData = this.tenantData;
          const addr = tenantData.address;
          const tel1 = tenantData.contact1;
          const tel2 = tenantData.contact2;
          const tenantID = tenantData.id;
          const tenantKa = tenantData.nameKana;
          const tenantName = tenantData.name;
          const data = {
            addr,
            tel1,
            tel2,
            tenantID,
            tenantKa,
            tenantName,
          };
          for (let i in data) {
            if (data[i] == null) {
              data[i] = "";
            }
          }
          tenantupdate(data)
            .then((res) => {
              // console.log(res);
              this.floorloading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                const { data } = res;
                const tenantlist = this.tenantlist;
                for (let i = 0; i < tenantlist.length; i++) {
                  const tenantlistId = tenantlist[i].id;
                  const dataId = data.id;
                  if (tenantlistId == dataId) {
                    tenantlist[i].address = data.address;
                    tenantlist[i].contact1 = data.contact1;
                    tenantlist[i].contact2 = data.contact2;
                    tenantlist[i].name = data.name;
                    tenantlist[i].nameKana = data.nameKana;
                  }
                }
                this.floorCancel();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.floorloading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开合同信息弹窗
    contractCompile(value) {
      console.log(value);
      const _newContractInfo = JSON.stringify([value]);
      const newContractInfo = JSON.parse(_newContractInfo);
      const _oldContractInfo = JSON.stringify(value);
      const oldContractInfo = JSON.parse(_oldContractInfo);
      this.newContractInfo = newContractInfo;
      this.oldContractInfo = oldContractInfo;
      this.contractvisible = true;
    },
    // 合同信息更新
    contractUpdate() {
      this.contractloading = true;
      const newContractInfo = this.newContractInfo;
      const oldContractInfo = this.oldContractInfo;
      const contractName = oldContractInfo.name;
      const contractType = oldContractInfo.id;
      const newStatus = newContractInfo[0].contractStatus;
      const oldStatus = oldContractInfo.contractStatus;
      const siteId = this.siteinfo.site.id;
      const contractId =
        oldContractInfo.contractId == undefined
          ? 0
          : oldContractInfo.contractId;
      const data = {
        contractName,
        contractType,
        newStatus,
        oldStatus,
        siteId,
        contractId,
      };
      console.log(newContractInfo, oldContractInfo);
      if (newStatus == oldStatus) {
        this.$warning({
          title: this.$t("customerCenter.a133"),
          content: this.$t("customerCenter.a134"),
          centered: true,
        });
      } else {
        console.log(data);
        addContract(data)
          .then((res) => {
            console.log(res);
            if (res.errorCode == "00") {
              this.contractloading = false;
              this.$message.success(this.$t("customerCenter.a136"));
              const { data } = res;
              const contractlist = this.contractlist;
              const result = contractlist.findIndex(
                (row) => row.contractId === data.id
              );
              console.log(result);
              contractlist[result].contractStatus = data.contractStatus;
              this.contractCancel();
            } else {
              // this.$message.error(res.msg);
              this.$message.success(this.$t("customerCenter.a137"));
            }
          })
          .catch((err) => {
            console.log(err);
            this.contractloading = false;
          });
      }
    },
    // 合同信息状态修改
    setContractStatus(value, record) {
      record.contractStatus = value;
    },
    // 关闭合同信息更新弹窗
    contractCancel() {
      this.contractvisible = false;
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#areaDetails {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px #bdbcbc;
  white-space: nowrap;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 10px 20px;
  padding: 20px 0px 10px 0px;
  font-size: 16px;
  color: rgba(12, 12, 12, 0.8);
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
}
.header img {
  width: 45px;
  margin-right: 20px;
}
.header span {
  margin-right: 30px;
}
.description {
  font-size: 12px;
  color: rgba(12, 12, 12, 0.65);
}
.header_title {
  display: flex;
  align-items: center;
}
.sitetitle {
  padding: 0px 20px;
}
.selectsite {
  height: 120px;
  overflow: auto;
  transition: 200ms;
  padding: 10px 20px 0px 20px;
}
.site {
  height: calc(100% - 120px);
  overflow: auto;
  transition: 200ms;
  padding: 10px 20px 0px 20px;
}
.box_sitechunk {
  height: 141px;
  padding: 0px 20px;
  box-shadow: 0px 5px 10px -5px #bdbcbc;
  transition: 500ms;
}
.not_box_sitechunk {
  height: 141px;
  padding: 0px 20px;
  transition: 500ms;
}
.main {
  height: calc(100% - 230px);
  padding: 10px 20px 0px 20px;
  overflow-y: auto;
}
.title {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.8);
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
}
.title::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 25px;
  background-color: #7682ce;
  margin-right: 15px;
}
.chunk {
  margin-bottom: 30px;
}
.calendarmodel {
  display: flex;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 4px;
  padding-bottom: 10px;
}
.calendar-container {
  position: relative;
  width: 450px;
  border-right: 1px solid #d9d9d9;
}
.calendar-select {
  position: absolute;
  top: 11px;
  left: 10px;
  display: flex;
  align-items: center;
}
.card_vessel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card {
  width: 150px;
  padding: 10px;
  background: #f2f4f7;
  border: 1px solid #ffffff;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 200ms;
}
.card:hover {
  border: 1px solid #7682ce;
}
.card img {
  width: 30px;
  margin-bottom: 5px;
}
.selectcard {
  width: 150px;
  padding: 10px;
  background: #f2f4f7;
  border: 1px solid #7682ce;
  color: #7682ce;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #bdbcbc;
  transition: 200ms;
}
.selectcard img {
  width: 30px;
  margin-bottom: 5px;
}
.empty {
  width: 150px;
  height: 1px;
}
.siteinfocontent {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
  padding: 10px 20px 0px 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
.siteinfo {
  display: flex;
  flex-wrap: wrap;
}
.line {
  margin: 0px 30px 15px 0px;
  font-size: 15px;
}
.calendarmodel {
  width: 870px;
  margin: 20px 10px;
}
.tablemodel {
  margin: 20px 0px;
}
.contractbut {
  display: flex;
  justify-content: flex-end;
}
.contractbut button {
  margin-left: 15px;
}
p {
  font-size: 12px;
  margin-bottom: 3px;
  color: #898989;
}
.contractStatus {
  display: flex;
}
.but {
  border: 1px solid rgba(12, 12, 12, 0.2);
  padding: 5px 30px;
  border-radius: 3px;
  margin-right: 15px;
  cursor: pointer;
  transition: 300ms;
}
.selectbut {
  border: 1px solid #7682ce;
  padding: 5px 30px;
  margin-right: 15px;
  border-radius: 3px;
  color: #7682ce;
  cursor: pointer;
  box-shadow: 0px 0px 3px #7682ce;
  transition: 300ms;
}
.swiper-container {
  width: 100%;
  padding: 15px 0px;
}
.swiper-slide {
  width: 150px;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.dot {
  padding: 0px 3px;
}

.calendar-ul {
  width: 400px;
  height: 319px;
  list-style: none;
  margin: 0;
  padding: 20px 0px 20px 20px;
  /* border-right: 1px solid #d9d9d9; */
  overflow: auto;
}
.calendar-li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  transition: 300ms;
}
.calendar-li-left {
  margin-left: 30px;
}
.calendar-li-right {
  margin-right: 20px;
}
.li-title {
  font-size: 16px;
}
.li-row {
  font-size: 12px;
  color: #817f7f;
}
.calendar-li-purple {
  position: absolute;
  width: 5px;
  height: 60px;
  background: #7682ce;
}
.calendar-li-orange {
  position: absolute;
  width: 5px;
  height: 60px;
  background: #f8b62e;
}
.button {
  margin: 0 0 20px 0px;
}
.a-select {
  width: 120px;
  margin-left: 30px;
}
.span {
  width: 80px;
  display: inline-block;
}
.connect {
  display: inline-block;
  margin: 0px 10px;
}
.festivalSet {
  overflow: hidden;
}
div /deep/.ant-badge-status-text {
  margin: 0px;
}
</style>