import { render, staticRenderFns } from "./areaDetails.vue?vue&type=template&id=20be6c54&scoped=true"
import script from "./areaDetails.vue?vue&type=script&lang=js"
export * from "./areaDetails.vue?vue&type=script&lang=js"
import style0 from "./areaDetails.vue?vue&type=style&index=0&id=20be6c54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20be6c54",
  null
  
)

export default component.exports